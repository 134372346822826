<template>
  <div id="app" class="grid-app">
    <Navbar></Navbar>
    <Sidenav></Sidenav>
    <Main></Main>
  </div>
</template>
<script>
import Navbar from '@/components/layout/Navbar'
import Main from '@/views/Main'
import Sidenav from '@/components/layout/Sidenav.vue'
export default {
  name: 'App',
  components: {
    Navbar,
    Main,
    Sidenav
  },
}
</script>
