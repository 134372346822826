<template>
  <nav class="navbar">
    <button class="menu-icon">
      <icon feather="menu"></icon>
    </button>
    <dropdown class="right">
      <button slot="button" class="navbar_profile dropdown-button">
        <!-- <div class="profile_user" v-if="user">
          <span class="username">
            {{ user.nombre }}
          </span>
          <span class="role">
            {{ user.tipo.toUpperCase() }}
          </span>
        </div>
        <div class="profile_thumbnail">{{ acronimo() }}</div> -->
        <!-- <img class="profile_thumbnail" src="images/profile.jpg" alt=""> -->
        <span>Mi perfil  </span><icon :feather="'chevron-down'"></icon>
      </button>
      <template slot="content">
        <p class="list-title">Mi perfil</p>
        <ul class="list">
          <li class="list-item">
            <router-link :to="{name: 'MisDatos'}">Mis datos</router-link>
          </li>
          <li class="list-item">
            <router-link :to="{name: 'CambiarPassword'}">Cambiar contraseña</router-link>
          </li>
          <hr/>
          <li class="list-item">
            <a class="" href="#" @click="logout">Cerrar sesión</a>
          </li>
        </ul>
      </template>
    </dropdown>
  </nav>
</template>
<script>
import Vuex from "vuex";
export default {
  name: "Navbar",
  computed: {
    ...Vuex.mapState(["user"]),
  },
  mounted() {},
  methods: {
    // acronimo() {
    //   let str = this.user.nombre;
    //   let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    //   let acronym = matches.join(""); // JSON
    //   return acronym;
    // },
    ...Vuex.mapActions({
      logoutAction: "logout",
    }),
    logout() {
      this.logoutAction().then(() => {
        this.$router.push({
          name: "Login",
        });
      });
    },
  },
};
</script>